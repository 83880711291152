import React from "react"

class BenifitsOf extends React.Component {

  render() {
    return (
      <section className="dapps nftstak pt-100 mb-0">
        <div className="container">
            <h2 className="heading-h2 text-center"><span className="bluecolor">Advantages Of </span>White Label NFT Staking Platform</h2>
            <p className='text-center'>Get a glimpse of the benefits you might enjoy by partnering with Coinsclone. Our ready-to-deploy NFT Staking Platform development unlocks limitless possibilities for elevating your own brand.</p>
          <div className="row">
                    <div className='d-flex flex-wrap justify-content-center px-0'>
                        <div className='fea-card'>
                            <h3>Rewards</h3>
                            <p className='pharagraph'>White Label NFT Staking Platform comes with a rewarding mechanism functionality to attract users to the platform. Through this feature, the users can earn huge rewards such as access to exclusive collections and native tokens proportional to the assets staked.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>High ROI</h3>
                            <p className='pharagraph'>Our White Label NFT Staking Platforms are designed to generate a better rate of return and incentives. Revenue can be driven through various channels, including transaction fees, advertisement fees, staking fees, and marketplace commissions.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Airdrops</h3>
                            <p className='pharagraph'>Airdrop Campaigns allow users to reward users for their loyalty, activity, participation, and contribution to the staking platforms. This functionality not only helps in attracting new users but also fosters an engaging community to support the platform.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Diverse Audience</h3>
                            <p className='pharagraph'>Your NFT Staking Platform can easily tap into the global market, expanding the brand presence beyond geographical limitations on a wider scale. Users from different regions with an internet connection can participate effortlessly in the staking process.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Transparency</h3>
                            <p className='pharagraph'>The core principle of blockchain technology offers the utmost transparency to every transaction conducted on the platform. Each transaction activity in our platform is recorded on the immutable blockchain instilling confidence and credibility among the audience.</p>
                        </div>
                        <div className='fea-card'>
                            <h3>Branding</h3> 
                            <p className='pharagraph'>Create your own brand with our customizable NFT Staking Platform development services. Attract more investors and partners with a platform that is tailored to your business's strategic requirements. Captivate the users with your unique identity and vision.</p>
                        </div>
                    </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BenifitsOf
