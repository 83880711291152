import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'


class WhyChoose extends React.Component {

  render() {

    return (
      <section className="whatis pt-100 mb-0 pb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2"><span className="bluecolor">Why is Coinsclone </span>the ideal NFT Staking Platform Development Company?</h2>
              <p className="pharagraph">Coinsclone is the leading NFT Staking Platform Development Company that has been in the growing surge for nearly a decade. We pride ourselves on providing top-notch NFT Staking Platform development services which are made entirely possible by our expert developers and consultants. With our experience, we have been offering NFT-based development services from simple token creation to advanced Dapp and blockchain development. Rest easy knowing that your NFT Staking Platform is securely fortified and staying in the hands of expert professionals of Coinsclone.</p>
            </div>
          </div>
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 text-center">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/crypto-dev/why-choose-crypto-development.webp"
                alt="best crypto app development company"
                placeholder='none'
                className='mt-3 mb-3'
                width={473}
              />
            </div>
            <div className="col-lg-6 col-md-12">
              <p className="pharagraph">Innovation isn't just a buzzword for us — it's at the core of everything we do. From startups to enterprises, we cater to a diverse range of clients, ensuring that each receives personalized attention tailored to their unique objectives. Your vision is unique, and so are our solutions, from custom features to intuitive interfaces, we craft platforms that captivate users. With state-of-the-art encryption and regular security audits, you can trust us to provide you with toughened protection. 
              </p>
              <ul className='mt-3'>
                <li>Tailored and scalable development services.</li>
                <li>Secured & Bug-free development solutions.</li>
                <li>24/7 customer support with technical assistance.</li>
                <li>On-time project delivery by professional experts.</li>
                <li>100% transparency with a client-centric approach.</li>
                <li>One-stop solution from development to deployment.</li>
                <li>Advanced tech stack and follows industry best practices.</li>
              </ul>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default WhyChoose