import React, { useState } from "react";
import { Accordion, Container } from 'react-bootstrap'



const FaqSection = () => {


  const [activeId, setActiveId] = useState('');

  function toggleActive(id) {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }

  return (
    <section className="faq pt-100 mb-5">
      <Container>
        <h2 className="heading-h2 text-center">Frequently Asked Questions</h2>
        <Accordion defaultActiveKey={activeId}>
            <div className={activeId === '0' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">

                <Accordion.Toggle onClick={() => toggleActive('0')} className="panel-toggle" eventKey="0">
                What is NFT Staking and how does it work?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="0">
                <div className="panel-body card-body">NFT Staking is the process of locking up the NFTs with smart contracts to earn revenue while providing liquidity to the platform for a specific period. The users just need to buy and lock up the NFTs in the staking protocol for a stipulated time thereby increasing liquidity and will earn rewards for doing so.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '1' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('1')} className="panel-toggle"  eventKey="1">
                Is it profitable to develop an NFT Staking Platform?
                </Accordion.Toggle>
              </div>
              <Accordion.Collapse eventKey="1">
                <div className="panel-body card-body">The answer is a big yes indeed, with the NFT Staking Platform creation there are ample opportunities. You can gain monetary benefits, build a strong and supportive community, and establish yourself as a profitable venture in the growing NFT realm. 
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '2' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('2')} className="panel-toggle"  eventKey="2">
                How do staking NFTs contribute to the growth of the NFT Marketplace development industry?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="2">
                <div className="panel-body card-body">Staking NFTs can contribute significantly to the growth of the NFT development industry by increasing user participation. Staking Rewards for users results in increased liquidity, which expands the activity within the platform benefitting both the users and owner of the platform.
                </div>
              </Accordion.Collapse>
            </div> 
            <div className={activeId === '3' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('3')} className="panel-toggle"  eventKey="3">
                How do staking rewards work on your platform, and what factors determine the reward distribution?
                </Accordion.Toggle>
              </div>  

              <Accordion.Collapse eventKey="3">
                <div className="panel-body card-body">Our NFT Staking Platform encourages users who participate in the process by rewarding them with passive income, governance rights, exclusive memberships, and other incentives. These rewards are fixed based on some factors. Some of them are the time duration of staking, percentage of NFTs staked, NFT’s price, and other influential factors.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '4' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('4')} className="panel-toggle"  eventKey="4">
                Which NFT Staking platforms are the most secure and best?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="4">
                <div className="panel-body card-body">Some of the best NFT staking platforms in terms of security purposes and reputation are Binance NFT, NFTX, Axie Infinity, and Band NFTs.
                </div>
              </Accordion.Collapse>
            </div>
            <div className={activeId === '5' ? 'panel-wrap active-panel card' : 'panel-wrap card'}>
              <div className="panel-header card-header">
                <Accordion.Toggle onClick={() => toggleActive('5')} className="panel-toggle"  eventKey="5">
                What technical aspects are needed to build an NFT staking platform?
                </Accordion.Toggle>
              </div>

              <Accordion.Collapse eventKey="5">
                <div className="panel-body card-body">Technically, an NFT staking platform requires self-executing smart contracts, a blockchain network, a database to store NFT transactions, algorithms to handle activities of staking and rewarding, an intuitive interface, a supportive backend server, and security protocols for safety measures.
                </div>
              </Accordion.Collapse>
            </div>
        </Accordion> 
      </Container>
    </section>
  )
}

export default FaqSection