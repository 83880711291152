import React from "react"

class HowtoGet extends React.Component {

  render() {
    return (

      <section className="icon whitelabel-use nftstak pt-100 secureof">
        <div className="container">
          <h2 className="heading-h2 text-center"><span className='bluecolor'>Types of </span>NFTs supported in our NFT Staking Platform</h2>
          <p className='text-center mw1030'>Specialized genres of NFTs that have a significant impact on our NFT Staking Platform development services.</p>
          <div className="row ">
            <div className='d-flex flex-wrap justify-content-evenly new-flex px-0'>
              <div className='leftdiv'>
                <h3 className='head3'>
                Art Based NFTs
                </h3>
                <p className="pharagraph">Through our NFT Staking Platform development services, the digital artworks can be staked for a certain period to earn revenue. By creating an NFT Staking Platform, especially for Art-based NFTs, you can easily summon like-minded people to form a supporting community.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Sport Based NFTs
                </h3>
                <p className="pharagraph">Sport-based NFTs have caused quite a stir in the NFT ecosystem. These sensational NFT users can be rewarded for staking the Sport-based NFTs in the NFT Staking Platforms. These are always in demand as they have an attentive audience from two aspects - Sports lovers and NFT enthusiasts. </p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Asset Based NFTs
                </h3>
                <p className="pharagraph mb-lg-0">Our NFT Staking Platform is capable of influencing Asset-based NFT holders by providing them with various staking features and reward benefits. These NFTs can be anything from real estate, and virtual lands, to a miniaturized flat which can exist both in physical and digital realms.</p>
              </div>
              <div className='leftdiv'>
                <h3 className='head3'>
                Automated NFTs
                </h3>
                <p className="pharagraph mb-lg-0">The NFT Staking Platform will benefit from gaining a brand presence and reaching the global audience effortlessly through supporting generative NFTs. Automated or generative NFTs are popular for flaunting themes and automatic execution of actions based on predetermined conditions.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

    )
  }
}

export default HowtoGet
