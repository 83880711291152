import React from 'react';
import Layout from "../components/layout";
import { Helmet } from "react-helmet";
import BannerCrypto from '../components/nft-stak/Banner';
import FaqSection from '../components/nft-stak/FaqSection';
import TechStack from '../components/nft-stak/TechStack';
import UserFriendly from '../components/Cryptoexchange/UserFriendly';
import WeProvide from '../components/nft-stak/WeProvide';
import HowtoGet from '../components/nft-stak/HowtoGet';
import BenefitsOf from '../components/nft-stak/BenefitsOf';
import DevProcess from '../components/nft-stak/DevProcess';
import WhyChoose from '../components/nft-stak/WhyChoose';

const NftStak = () => (
  <section className="cryptoex">
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://www.coinsclone.com/nft-staking-platform-development/" />
        <title>NFT Staking Platform Development Company</title>
        <meta name="description" content="Coinsclone is your ideal NFT Staking Platform development company, which assists in creating your own NFT Staking Platform allowing users to stake NFTs for revenue." />
        <meta name="keywords" content="NFT Staking Platform Development Company, NFT Staking Platform Development Services" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="NFT Staking Platform Development Company" />
        <meta property="og:description" content="Coinsclone is your ideal NFT Staking Platform development company, which assists in creating your own NFT Staking Platform allowing users to stake NFTs for revenue." />
        <meta property="og:url" content="https://www.coinsclone.com/nft-staking-platform-development/" />
        <meta property="og:image" content="https://coinsclone.mo.cloudinary.net/images/nft-staking/nft-staking-platform.webp" />
        <meta property="og:site_name" content="Coinsclone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@Coinsclone" />
        <meta name="twitter:creator" content="@Coinsclone" />
        <meta name="twitter:description" content="Coinsclone is your ideal NFT Staking Platform development company, which assists in creating your own NFT Staking Platform allowing users to stake NFTs for revenue." />
        <meta name="twitter:title" content="NFT Staking Platform Development Company" />
        <meta name="twitter:image" content="https://coinsclone.mo.cloudinary.net/images/nft-staking/nft-staking-platform.webp" />
      </Helmet>
      
      <BannerCrypto />
      <div className="breadcrumb wallet-banner mb-0">
        <div className="container">
          <span><a href="https://www.coinsclone.com/">Home</a> | NFT Staking Platform Development Company</span>
        </div>
      </div>
      <WeProvide />
      <HowtoGet />
      <DevProcess />
      <BenefitsOf />
      <WhyChoose />
      <TechStack />
      <FaqSection />
      <UserFriendly /> 
    </Layout>
  </section>
)

export default NftStak
