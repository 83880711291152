import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">  
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-0 mb-md-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>NFT Staking Platform</span> Development Company</h1>
                <p className='pharagraph mb-0'>Partner with Coinsclone, a premier NFT Staking Platform development company where we bridge the gap between your vision and reality.  Our NFT Staking Platform development services will assist you in attracting a dedicated user base. Empower yourself in this competitive evolving ecosystem with our NFT specialists and experienced experts.</p>
                <a href="https://www.coinsclone.com/contact-us/" className="bluebtn mt-4">Talk to our Experts</a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/nft-staking/nft-staking-platform.webp"
                alt="Nft Staking Platform Development Company"
                className='d-none d-md-block float-end'
                width={588}
              />
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection